import React, { useEffect, useState, useRef } from "react";
import { Menu, Dropdown, Modal } from "antd";
import { useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { notification } from "antd";

import { getStorage } from "../../../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../../../dataProvider/constant";
import { startSipnner, stopSipnner } from "../../../../../../utils/spinner";
import { Strings } from "../../../../../../dataProvider/localize";
import POEmailWrapper from "./POEmailWrapper";

import styles from "../../../JobStyle.module.css";


export default function IssuePO({ jobId, task, frequency, jobLabel, refreshJobBoard, isDisabled }) {
  const dispatch = useDispatch()
  const controller = useRef();
  const [emailModal, setEmailModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [isPODisabled, setPODisabled] = useState(true);

  const downloadDocument = async (url, notifyError = true) => {
    return new Promise((resolve, reject) => {
      fetch(url, { headers: { "Cache-Control": 'no-cache' } }).then((res) => {
        if (!res.ok) {
          reject("response not okay");
        } else {
          res.blob().then((blob) => {
            var file = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = file;
            a.setAttribute("download", true);
            let fileName = url.substring(url.lastIndexOf('/') + 1).toString();
            a.download = fileName;
            a.click();
            a.remove();
            resolve();
          }).catch((e) => {
            reject(e)
          })
        }
      }).catch(() => {
        reject();
      })
    }).catch((e) => {
      if (notifyError) {
        notification.error({
          message: Strings.error_title,
          description: Strings.file_download_error,
          className: "ant-error",
        })
      }
    })
  }

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      controller.current = new AbortController();
      setEmailModal(true);
    }
    if (e.key === "2") {
      if (frequency && frequency.fm_quote_task_pos && frequency.fm_quote_task_pos.length > 0 && frequency.fm_quote_task_pos[0].po_pdf_file) {
        startSipnner(dispatch)
        downloadDocument(frequency.fm_quote_task_pos[0].po_pdf_file).then(() => {
          stopSipnner(dispatch)
        });
      }
    }
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        [Re] Issue PO
      </Menu.Item>
      <Menu.Item key="2">
        Download PO
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
    let currentOrgId = adminDetails && adminDetails.organisation && adminDetails.organisation.id ? adminDetails.organisation.id : '';
    if (frequency && frequency.contractor_details) {
      setPODisabled(frequency.contractor_details.length && ((frequency.contractor_details[0].org_id === currentOrgId || frequency.contractor_details.length !== 1) || (frequency.job_board_task_state && frequency.job_board_task_state.toLowerCase() === "values approved for invoice")))
    }
    const { task_id, task_name } = task;
    let requiredTaskValues = {
      task_id,
      quote_task_id: frequency.quote_task_id,
      task_label: frequency.quote_task_label,
      task_name,
      job_id: jobId,
      site_id: frequency.site_id,
      task_amount: frequency.task_amount,
      contractor_task_cost: frequency.budget ? frequency.budget : null,
      contractor_id: frequency.contractor_id ? frequency.contractor_id : null,
      contractor_name: frequency.resource_to ? frequency.resource_to : null,
      po_issued_status: frequency.hasOwnProperty('po_issued_status') ? frequency.po_issued_status : 0
    };
    setSelectedTask(requiredTaskValues)
  }, [task, frequency]);

  return (
    <div className="">
      {frequency.fm_quote_task_pos && frequency.fm_quote_task_pos.length > 0 ? (
        <Dropdown overlay={menu} overlayClassName="cs-v3-dropdown" disabled={isPODisabled || isDisabled}>
          <button className={`${styles.PrimaryButton} d-flex align-items-center`} style={{ gap: "7px" }}>
            <span>Issue PO</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="14">
              <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
            </svg>
          </button>
        </Dropdown>) : (
        <button className={`${styles.PrimaryButton}`} onClick={() => handleMenuClick({ key: "1" })} disabled={isPODisabled || isDisabled}>
          Issue PO
        </button>
      )}
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={emailModal}
        onCancel={() => setEmailModal(false)}
        afterClose={() => { }}
        maskClosable={false}
        destroyOnClose={true}
        width={850}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <POEmailWrapper
          selectedTasks={[selectedTask]}
          jobId={jobId}
          siteId={frequency.site_id}
          jobLabel={jobLabel}
          siteName={frequency.site_name}
          isReIssue={frequency && frequency.fm_quote_task_pos && frequency.fm_quote_task_pos.length > 0 ? true : false}
          onClose={() => setEmailModal(false)}
          closeTaskSelectionModal={() => { return; }}
          refreshJobBoard={refreshJobBoard}
        />
      </Modal>
    </div>
  )
}