import React, { Suspense, useEffect, useState } from "react";
import { withRouter, useParams, useLocation, useHistory } from "react-router-dom"
import Header from "./Header";
import CalendarContainer from "../JobDetails/JobBoard/CalendarPanel/CalendarContainer";
import { Tabs, Icon, notification } from "antd";
import { jobTabs } from "./JobTabs";
import { getStorage } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../actions/v2/AllocationActions";
import * as jobDocActions from "../../../actions/v2/JobDocumentActions";
import { getNewJobDetails, getJobRosterDetails } from "../../../actions/v2/JobCalendarActions";
import { ADMIN_DETAILS } from "../../../dataProvider/constant";
import { calendarIcon } from "./Icons";
import { legends } from "../JobDetails/JobBoard/legendsConfig";
import styles from "./JobStyle.module.css";
import "./JobStyles.css";
import { Strings } from "../../../dataProvider/localize";
import moment from "moment";

const { TabPane } = Tabs;

function JobContainer(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let { jobId } = useParams();

  const selectedJobDetails = useSelector((state) => state.JobCalendar.selectedJobDetails);
  const allTasks = useSelector((state) => state.JobCalendar.tasks)
  const hasSwms = useSelector((state) => state.Job.jobSWMSId ? true : false)
  const oldSwms = useSelector((state) => state.Job.swmsType === "JOB_SWMS" ? true : false)
  const isOutsourced = useSelector((state) => !state.Job.jobHeader.account_managers || (state.Job.jobHeader.account_managers && !state.Job.jobHeader.account_managers.length) ? false : true);
  const isDisabled = useSelector((state) => state.Job.jobHeader.ar_job_state && state.Job.jobHeader.ar_job_state.toLowerCase() === "not found" ? true : false)
  const [showJobCalendar, setShowJobCalendar] = useState(false);
  const [tab, setTab] = useState("1");

  const [selectedTask, setSelectedTask] = useState(null);
  const jobTasks = useSelector((state) => state.Job.parentTasks);

  useEffect(() => {
    dispatch(actions.resetTimesheets());
    dispatch(jobDocActions.resetJobDoc());
    if (selectedJobDetails.label) {
      document.title = `${selectedJobDetails.label} - Service Farm`
    }
  }, [selectedJobDetails.label])

  useEffect(() => {
    if (location.goToTab && location.highlightId) {
      if (location.goToTab === "Allocations") {
        handleTabChange("4");
      }
      if (location.goToTab === "Timesheets") {
        handleTabChange("7");
      }
    }
  }, [location])

  useEffect(() => {
    if (location.goToTab === "ViewFrequency") {
      let selectedTaskDetails = jobTasks && jobTasks.filter(task => task.task_id === location.taskId);
      if (selectedTaskDetails.length) {
        setSelectedTask(selectedTaskDetails[0])
        setTab("10")
      }
    }

  }, [jobTasks]);

  useEffect(() => {
    dispatch(getNewJobDetails(null, jobId, true)).catch(err => {
      console.log(err);
      //   notification.error({
      //     message: Strings.error_title,
      //     description: err ? err : "",
      //     className: "ant-error"
      //   })
    })
  }, [jobId])

  const handleTabChange = (tabKey) => {
    if (tabKey === "10") {
      if (selectedTask) {
        setTab(tabKey);
      }
    } else {
      setTab(tabKey);
      actions.resetAction("COPY");
      if (tabKey === "3") {
        jobDocActions.resetJobDoc();
        const currentOrganization = JSON.parse(getStorage(ADMIN_DETAILS))
          ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation
          : null;

        const requests = [];
        let organisationsData = [{ id: currentOrganization.id, name: currentOrganization.name, isOrg: true }];
        let organisations = [currentOrganization.id];
        selectedJobDetails.tasks && selectedJobDetails.tasks.forEach(task => {
          task.contractor_details && task.contractor_details.forEach(contractor => {
            if (organisations.indexOf(contractor.org_id) === -1 && contractor.org_id !== currentOrganization.id) {
              organisations.push(contractor.org_id);
              organisationsData.push({ id: contractor.org_id, name: contractor.contractor_name, isOrg: false })
            }
          })
        });

        selectedJobDetails.jobBoardTasks && selectedJobDetails.jobBoardTasks.forEach(task => {
          task.contractor_details && task.contractor_details.forEach(contractor => {
            if (organisations.indexOf(contractor.org_id) === -1 && contractor.org_id !== currentOrganization.id) {
              organisations.push(contractor.org_id);
              organisationsData.push({ id: contractor.org_id, name: contractor.contractor_name, isOrg: false })
            }
          })
        })

        dispatch(jobDocActions.setContractorOrganisations(organisationsData));

        requests.push(dispatch(jobDocActions.getJobDocumentStaffList(JSON.stringify(organisations))));
        requests.push(
          dispatch(jobDocActions.getJobDocumentOrgCertificates(
            JSON.stringify(organisations),
            selectedJobDetails.id,
            organisationsData
          ))
        );
        requests.push(dispatch(jobDocActions.getJobDocumentDetails(selectedJobDetails.id, selectedJobDetails.scopeId)));
        Promise.all(requests)
          .then((res) => { })
          .catch((err) => { });
      }
      if (tabKey === "4") {
        let startDate = selectedJobDetails.start ? moment.utc(selectedJobDetails.start).format("YYYY-MM-DD 00:00:00") : "";
        let endDate = selectedJobDetails.end ? moment.utc(selectedJobDetails.end).format("YYYY-MM-DD 00:00:00") : "";
        dispatch(getJobRosterDetails(selectedJobDetails.id, startDate, endDate,)).then((res) => {
          if (res.roster_details && res.roster_details.length === 1 && res.roster_details[0].roster_id) {
            history.push({
              pathname: `/dashboard/rostering/${res.roster_details[0].roster_id}`,
              state: {
                roster: res.roster_details[0].roster
              }
            })
          } else {
            if (res.roster_details && res.roster_details.length > 0) {
              history.push({
                pathname: "/dashboard/rostering",
                state: {
                  filters: res.roster_details[0].roster
                }
              })
            } else {
              notification.warn({
                message: "No Roster found for the Job, Please create a Roster."
              })
              history.push({
                pathname: "/dashboard/rostering",
                state: {
                  ...(res.job_details ? { filters: res.job_details } : {})
                }
              })
            }
          }
        }).catch(err => {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: "ant-error"
          })
        })
      }
    }
  };

  const onViewFrequency = (task) => {
    setSelectedTask(task);
    setTab("10");
  }

  const handleJobCalendarToggle = () => {
    setShowJobCalendar(!showJobCalendar)
  }

  return (
    <div className="job-board-v2" style={{ height: "100%", background: "#fff", textAlign: "initial", padding: "10px" }}>
      <div className={styles.newContainer}>
        <div className="p-1" style={{ background: "#fff", borderRadius: "30px", height: "100%" }}>
          <div className="my-1" style={{ height: "100%", overflow: "hidden auto" }}>
            <div className="d-flex align-items-center justify-content-between px-2 mb-2 pb-2" style={{ borderBottom: "1px solid #eaeaea" }}>
              <div className={styles.PageHeading}>Job Details</div>
              <Header jobId={jobId} />
            </div>
            <div className={styles.blankContainer}>
              {/* <div className={styles.CalendarFloatIcon} onClick={() => handleJobCalendarToggle()}>
                <Icon component={calendarIcon} />
              </div> */}
              <div className="position-relative">
                <div className={`slider ${showJobCalendar ? 'slide-in' : 'slide-out'}`}>
                  <div className="slide-in-content">
                    <div>
                      <div className="d-flex justify-content-center align-items-center">
                        {legends.map((legend) => (
                          <div
                            key={legend.key}
                            style={{
                              background: `${legend.color}`,
                              color: 'white',
                              borderRadius: '5px',
                              margin: '0 20px 5px',
                              padding: '4px',
                              fontSize: '0.8rem',
                              fontWeight: 'bold',
                              minWidth: '5rem',
                              textAlign: 'center'
                            }}
                          >
                            {legend.label}
                          </div>))}
                      </div>
                      <CalendarContainer events={allTasks} tooltipAccessor="task_title" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Suspense fallback={<div>Loading</div>}>
              <Tabs defaultActiveKey="1" activeKey={tab} type="card" tabBarGutter={0} onChange={handleTabChange} className="job-board-tabs" destroyInactiveTabPane={true}>
                {jobTabs({ selectedTask: selectedTask, hasSwms: hasSwms, oldSwms: oldSwms, isOutsourced: isOutsourced, isDisabled: isDisabled }).map((tab) => (
                  <TabPane disabled={tab.id === 10 && !selectedTask} tab={tab.tab} key={tab.id} to={tab.to} className="job-board-custom-tab" >
                    {<tab.component jobId={jobId} selectedJobDetails={selectedJobDetails} selectedTask={selectedTask} setSelectedTask={onViewFrequency} />}
                  </TabPane>
                ))}
              </Tabs>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(JobContainer);
