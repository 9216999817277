import { notification } from "antd";
import { Strings } from "../../../../../dataProvider/localize";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Collapse, Icon } from "antd";
import ChemicalsTable from "./ChemicalsTable";
import InsurancesTable from "./InsurancesTable";
import StaffAllocate from "./StaffAllocate";
import TasksTable from "./TasksTable";
import * as actions from "../../../../../actions/v2/JobDocumentActions";
import { withRouter } from "react-router";
import { reduxForm } from "redux-form";
import { handleFocus, capitalizeFirst } from "../../../../../utils/common";
import styles from "./PreAllocateStyles.module.css";
import jobStyles from "../../../Job/JobStyle.module.css";

const { Panel } = Collapse;

export class PreAllocateContainer extends Component {
  onSubmit = (values) => {
    if (
      !Object.keys(values).some(
        (keyName) => keyName.startsWith("select_task_") && values[keyName]
      )
    ) {
      return notification.warning({
        message: Strings.warning_title,
        description: "Please select at least one task from the Tasks table",
        onClick: () => { },
        className: "ant-warning",
      });
    }
    const { staffList, jobDetails, actions, isJobSwms } = this.props;
    const allTasks = jobDetails.sites && jobDetails.sites.length && jobDetails.sites[0].tasks ? jobDetails.sites[0].tasks : []
    const jobDocTasks = [];
    let selectedStaffUserNames = [];
    const chemicals = [];
    const certificates = [];
    const swmsDocs = [];
    let swmsPdfSelected = 0;
    let selectedAdditionalDocs = [];

    selectedStaffUserNames = values.selected_staffs ? values.selected_staffs.map(v => v.value) : [];

    Object.keys(values).forEach((key) => {

      if (key.startsWith("select_task_") && values[key]) {
        jobDocTasks.push(+key.split("_")[2]);
      }
      if (key.startsWith("select_chemical_") && values[key]) {
        chemicals.push(+key.split("_")[2]);
      }
      if (key.startsWith("select_insurance_") && values[key]) {
        certificates.push(+key.split("_").pop());
      }
      if (key.startsWith("select_additional_doc_") && values[key] === true) {
        selectedAdditionalDocs.push(parseInt(key.split("_").pop()));
      }
      if (key.startsWith("select_swms_document_") && values[key]) {
        if (isJobSwms) {
          swmsPdfSelected = 1;
        } else {
          swmsDocs.push(+key.split("_").pop());
        }
      }
    });

    const jobDocTaskIds = allTasks.filter(x => jobDocTasks.indexOf(x.id) !== -1).map(x => x.task_id);

    let sds = this.props.chemicals ? this.props.chemicals.filter(x => chemicals.indexOf(x.chemical_id) !== -1) : [];

    const selectedStaff = [];
    staffList.service_agents.forEach((sa) => {
      sa.site_supervisors.forEach((ss) => {
        if (selectedStaffUserNames.includes(ss.user_name)) {
          selectedStaff.push({
            org_id: sa.id,
            selected_for_job_doc: 1,
            user_name: ss.user_name,
            user_license: ss.user_licences.map((lic) => lic.id),
          });
        }
      });
      sa.staffs.forEach((st) => {
        if (selectedStaffUserNames.includes(st.user_name)) {
          selectedStaff.push({
            org_id: sa.id,
            user_name: st.user_name,
            selected_for_job_doc: 1,
            user_license: st.user_licences.map((lic) => lic.id),
          });
        }
      });
    });

    const requestBody = {
      job_doc_tasks: jobDocTasks,
      job_allocated_users: selectedStaff,
      job_number: jobDetails.job_number,
      client_id: jobDetails.client_id,
      quote_id: jobDetails.quote_id,
      job_id: jobDetails.job_id,
      certificates: certificates,
      chemicals: chemicals,
      swms_pdf_selected: swmsPdfSelected,
      additional_docs: selectedAdditionalDocs,
      swms_forms_selected: swmsDocs.toString(),
      job_doc_task_ids: [...new Set(jobDocTaskIds)],
      sds: [...sds]
    };

    actions
      .createJobDocAllocation(requestBody)
      .then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-success",
        });
        actions
          .getJobDocumentDetails(jobDetails.job_id, this.props.selectedJobDetails.scopeId)
          .then((res) => { })
          .catch((err) => { });
      })
      .catch((message) =>
        notification.error({
          message: Strings.error_title,
          description: message
            ? Array.isArray(message)
              ? message[0].message
              : Strings.generic_error
            : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        })
      );
  };

  render() {
    const {
      staffList,
      jobDetails,
      certificates,
      additionalDocs,
      swmsDocument,
      jobDocumentDetails,
      jobDocumentCreatorDetails,
      handleSubmit,
      chemicals,
      formValues,
      change,
      onShowModal,
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          {jobDetails && Object.keys(jobDetails).length &&
            jobDetails.sites &&
            jobDetails.sites.length ? (
            <section className="px-2 mb-3">
              <div className={styles.collapseBorder}>
                <TasksTable sites={jobDetails.sites} jobDetails={jobDetails} />
              </div>
            </section>
          ) : null}

          {certificates && Object.keys(certificates).length ? (
            <section className="px-2 mb-3">
              <div className={styles.collapseBorder}>
                <div className={jobStyles.SectionHeading}>Insurance & OHS Documents</div>
                <div className={`${jobStyles.card} py-2`}>
                  <Collapse bordered={false} className="custom-section-v2-collapse" expandIconPosition="right" expandIcon={({ isActive }) => (
                    <Icon
                      style={{ color: "#2f1f41" }}
                      type="caret-right"
                      rotate={isActive ? 90 : 0}
                    />
                  )}>
                    {certificates.map((org, index) => org.certificates ? (
                      <Panel
                        style={{ border: '0px !important', marginTop: index === 0 ? "0px" : "10px" }}
                        header={
                          <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>{`${capitalizeFirst(org.name, true)} ${org.isOrg ? '(Org)' : ''}`}</div>
                        }
                        key={`certificate_panel_${org.id}`}
                        className={`custom-swms-category-header ${jobStyles.card}`}>
                        <InsurancesTable
                          licenses={org.certificates && org.certificates.length ? org.certificates : []}
                          additionalDocs={additionalDocs && additionalDocs.length ? additionalDocs.filter(doc => doc.contractor_id.toString() === org.id.toString()) : []}
                          orgId={org.id}
                          jobId={this.props.jobDetails && this.props.jobDetails.job_id ? this.props.jobDetails.job_id : null}
                          swmsDocument={swmsDocument && swmsDocument.length ? swmsDocument.filter(x => x.organisation_id.toString() === org.id.toString()) : []}
                          change={change}
                          formValues={formValues}
                          jobDetails={jobDetails}
                          onShowModal={onShowModal}
                        />
                      </Panel>
                    ) : '')}
                  </Collapse>
                </div>
              </div>
            </section>
          ) : ''}

          {chemicals.length ? (
            <section className="px-2 mb-1">
              <div>
                <div className={jobStyles.card}>
                  <Collapse bordered={false} className="custom-section-v2-collapse" expandIconPosition="right" expandIcon={({ isActive }) => (
                    <Icon
                      style={{ color: "#2f1f41" }}
                      type="caret-right"
                      rotate={isActive ? 90 : 0}
                    />
                  )}>
                    <Panel
                      style={{ border: '0px !important' }}
                      header={
                        <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Chemical MSDS</div>
                      }
                      className={`custom-swms-category-header ${jobStyles.card}`}>
                      <ChemicalsTable onShowModal={onShowModal} />
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </section>
          ) : ''}

          {Object.keys(staffList).length && staffList.service_agents.length ? (
            <section className="px-2">
              <div>
                <div className={`custom-swms-category-header ${jobStyles.card}`}>
                  <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading font-weight-normal`}>
                    <div className="d-flex align-items-center justify-content-between"><div>Staff</div></div>                </div>
                  <div className={`${jobStyles.OpenBodyBorder} p-2`}>
                    <StaffAllocate
                      staffList={staffList}
                      formValues={formValues}
                      change={this.props.change}
                    />
                  </div>
                </div>
              </div>
            </section>
          ) : ''}

          {
            <section className="px-2 my-4">
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className={`${jobStyles.PrimaryButton}`}
                >
                  Allocate
                </button>
              </div>
            </section>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let allCerts = state.JobDocument.certificates ? state.JobDocument.certificates : [];
  let newCerts = [];
  if (allCerts.length > 0) {
    newCerts = [...allCerts.map(org => ({ ...org, certificates: org.certificates.filter(x => x.active === 1) }))]
  }
  return {
    staffList: state.JobDocument.staffList,
    chemicals: state.JobDocument.chemicals,
    jobDetails: state.JobDocument.jobDetails,
    certificates: newCerts,
    additionalDocs: state.JobDocument.additionalDocs,
    swmsDocument: state.JobDocument.swmsDocument,
    licenseTypes: state.JobDocument.licenseTypes,
    jobDocumentDetails: state.JobDocument.jobDocumentDetails,
    jobDocumentCreatorDetails: state.JobDocument.jobDocumentCreatorDetails,
    isJobSwms: state.JobDocument.isJobSwms,
    formValues:
      state.form &&
        state.form.PreAllocateContainer &&
        state.form.PreAllocateContainer.values
        ? state.form.PreAllocateContainer.values
        : {},
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "PreAllocateContainer",
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
  })
)(PreAllocateContainer);
