import React, { useState } from "react";
import { components } from "react-select";
import { Icon, notification } from "antd";
import { Field, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";

import { customInput } from "../../../common/custom-input";
import { removeCurrencyFormat } from "../../../../utils/common";
import { Strings } from "../../../../dataProvider/localize";
import { CustomAsyncSelect } from "../../../common/new-core-ui/CustomAsyncSelect";
import { getContractorsBySearch, outsourceTask } from "../../../../actions/v2/JobAllocatonActions";

import SelectV2SingleStyle from "../SelectV2SingleStyle";
import styles from "../JobStyle.module.css";

const FrequencyResourcing = (props) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);

  const getDefaultContractorOption = () => {
    let defaultOrg = [];
    return props.contractor && props.contractor.org_id ? [
      {
        label: `${props.contractor.contractor_name}${props.contractor.organisation_type && props.contractor.organisation_type === 1 ? ' (Org)' : ''}`,
        value: props.contractor.org_id
      },
    ] : defaultOrg
  }

  const handleInputChange = (search) => {
    return search.trim().length > 0 ?
      Promise.resolve(dispatch(getContractorsBySearch(search, 1)).then(res =>
        res.contractors.map(contractor => ({
          label: `${contractor.contractor_name}${contractor.contractor_type && contractor.contractor_type === 1 ? ' (Org)' : ''}`,
          value: contractor.contractor_id
        }))
      )) : Promise.resolve([]);
  }

  const onSubmit = (values) => {
    setLoading(true);

    let formData = {
      job_id: props.jobId,
      outsourced_budget: parseFloat(removeCurrencyFormat(values.outsourced_budget)),
      outsoured_notes: values.notes,
      assignee_id: values.contractor.value,
      quote_task_id: props.frequency.quote_task_id ? props.frequency.quote_task_id : 0,
      // ...(props.frequency.start_date ? { sug_start_date: dayjs(props.frequency.start_date).format("YYYY-MM-DD") } : {}),
      // ...(props.frequency.end_date ? { sug_finish_date: dayjs(props.frequency.end_date).format("YYYY-MM-DD") } : {}),
      ...(props.frequency.task_invoice_id ? { task_invoice_id: props.frequency.task_invoice_id } : {})
    }
    dispatch(outsourceTask(formData)).then((message) => {
      props.refreshJobBoard();
      props.reset();
      setLoading(false);
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error,
        className: 'ant-success'
      });
    }).catch(err => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      }
    });
  }

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
            <div className={styles.Label}>Resourcing</div>
            {/* <Field disabled={true} placeholder="Description" name="contractor_name" className={styles.SingleLine} component={customInput} autoComplete="off" /> */}
            <Field name="contractor"
              component={CustomAsyncSelect}
              clearable={true}
              placeholder="Search Contractor"
              styles={SelectV2SingleStyle}
              otherProps={{
                isDisabled: loading,
                cacheOptions: true,
                hideSelectedOptions: false, defaultOptions: selectedOption.length ? selectedOption : getDefaultContractorOption(),
                onChange: (value => { setSelectedOption([value]); props.change("contractor", value) }),
                components: { dropdownIndicator: <components.DownChevron /> }
              }}
              loadOptions={handleInputChange}
              dropdownIndicator={<Icon type="down" style={{ fontSize: "13px" }} />}
            />
          </div>
          <div className="col-2">
            <div className={styles.Label}>Resourcing Value</div>
            <Field name="outsourced_budget" className={styles.SingleLine} component={customInput} autoComplete="off" />
          </div>
          <div className="col-4">
            <div className={styles.Label}>Notes</div>
            <Field
              disabled={loading}
              name="notes" className={styles.SingleLine} component={customInput} autoComplete="off" />
          </div>
          <div className="col-1">
            <button type="submit" disabled={props.pristine} className={`${styles.PrimaryButton} ${styles.SmallPrimaryButton} mt-3`}>Assign</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: true,
})(FrequencyResourcing)