import React, { useRef, useState, useEffect } from "react";
import { Add, Remove } from '@mui/icons-material';
import { Typography, IconButton, LinearProgress, Tooltip } from "@mui/material";
import TableView from "./TableView";
import styles from "../../v2Styles.module.css";
import { notification } from "antd";
import { Strings } from "../../../../dataProvider/localize";
import { scopeApiInstance } from "../../../../dataProvider/axiosHelper";

export default function TableRow({ row, dataTable = {}, parentData = null, triggerModal, refreshKey }) {

  const [expanded, setExpanded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const controller = useRef();
  const toggleRow = () => {
    expanded ? (setExpanded(false)) : setExpanded(true);
  }

  const getChildData = () => {
    setLoading(true);
    controller.current = new AbortController();
    let fetchParams = {};
    if (dataTable.innerTable.fetchParams && dataTable.innerTable.fetchParams.length > 0) {
      dataTable.innerTable.fetchParams.forEach(param => {
        fetchParams[param.key] = row[param.value]
      })
    }
    return scopeApiInstance.get(
      dataTable.innerTable.apiEndPoint,
      {
        params: {
          ...fetchParams
        }, signal: controller.current.signal
      },
    ).then(res => {
      setLoading(false);
      if (res && res.data && res.data.data) {
        if (dataTable.innerTable.dataGetter) {
          setTableData(dataTable.innerTable.dataGetter(res.data.data));
        } else {
          setTableData(res.data.data.rows ? res.data.data.rows : []);
        }
      }
    }).catch(error => {
      if (error) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error,
          className: "ant-error"
        });
      }
    })
  }

  useEffect(() => {
    if (dataTable.innerTable && dataTable.innerTable.apiEndPoint && expanded) {
      getChildData()
    }
  }, [expanded])

  useEffect(() => {
    if (dataTable.innerTable && dataTable.innerTable.apiEndPoint && expanded) {
      getChildData()
    }
  }, [refreshKey]);

  useEffect(() => {
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, [])

  return (
    <>
      <tr>
        {dataTable.innerTable ?
          <td>
            <IconButton className="p-0" style={{ height: "auto", color: "#c0b4ff" }} onClick={() => toggleRow()}>
              {expanded ? (
                <Remove />
              ) : (
                <Add />
              )}
            </IconButton>
          </td>
          : null}

        {dataTable && dataTable.columns ? dataTable.columns.map((column, index2) => (
          <td
            {...(column.hideTooltip ? {} : {
              title: column.getValue ? column.getValue(row[column.keyName], parentData, row) : row[column.keyName]
            })}
            key={index2}
            className={column.columnClassName}>
            {column.customView ?
              column.customView(column.keyName, row[column.keyName], row, parentData, triggerModal)
              : (
                // <Tooltip title={column.getValue ? column.getValue(row[column.keyName], parentData) : row[column.keyName]}>
                <Typography
                  className={styles.TableText}
                  {...(column.textAlign ? { textAlign: column.textAlign } : {})}
                  noWrap
                >
                  {column.getValue ? column.getValue(row[column.keyName], parentData, row) : row[column.keyName]}
                </Typography>
                // </Tooltip>
              )}
          </td>
        )) : null}
      </tr>
      {expanded ? (
        dataTable.innerTable && (
          <>
            <tr>
              <td className={dataTable.innerTable.sameWidth ? "px-0" : ""} colSpan={dataTable.columns.length + 1}>
                <div className="container-fluid">
                  <div className="row">
                    <div className={`col-12 ${dataTable.innerTable.sameWidth ? "px-0" : ""}`}>
                      <TableView
                        dataTable={dataTable.innerTable} parentData={row} rowData={tableData} isChild={true} parentLoading={loading} />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </>
        )
      ) : null}
    </>
  )
}