import { CONTRACTORS_URL, JOB_BOARD_PREVIEW_PO_DATA, OUTSOURCE_TASK_URL, JOB_BOARD_UPLOAD_INVOICE, JOB_BOARD_EXPENCES, ALLOCATE_MANAGER_URL, JOB_BOARD_ADD_NOTES, JOB_BOARD_PURCHASES, JOB_BOARD_WORK_FLOW_HISTORY, JOB_BOARD_NOTES_URL, SLUG_EMAIL_CONTENT_URL } from "../../dataProvider/constant";
import { axiosV2Instance, scopeApiInstance, scopeV2AxiosInstance, baseApiInstance } from "../../dataProvider/axiosHelper";
import { Strings } from "../../dataProvider/localize";
import { startSipnner, stopSipnner } from "../../utils/spinner";

export const getContractorsBySearch = (search = "", includeOrg = "0", page = 1) => dispatch => {
  let url = CONTRACTORS_URL;
  if (search.length && search.length > 0) {
    url = `${url}?search=${search}&includeOrg=${includeOrg}&page=${page}`;
  } else {
    url = `${url}?page=${page}`
  }
  return axiosV2Instance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const outsourceTask = (formData, loading = true) => dispatch => {
  if (loading) { startSipnner(dispatch); }
  let url = OUTSOURCE_TASK_URL;
  return scopeApiInstance.put(url, formData).then(async (res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      if (loading) { stopSipnner(dispatch) }
    })
}

export const allocateManager = (formData) => dispatch => {
  let url = ALLOCATE_MANAGER_URL;
  return scopeV2AxiosInstance.put(url, formData).then(async (res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getNotesDetails = (page = 1, params = {}, abortController) => () => {
  return scopeApiInstance.get(JOB_BOARD_NOTES_URL, { params: { page, ...params }, ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getPOEmailContent = (abortController) => () => {
  return baseApiInstance.get(SLUG_EMAIL_CONTENT_URL, { ...abortController, params: { slug: "client_po_status" } }).then(res => {
    if (res && res.data && res.data.data && res.data.data.result && res.data.data.result.length === 1) {
      return Promise.resolve(res.data.data.result[0]);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const addNotes = (formData) => dispatch => {
  let url = JOB_BOARD_ADD_NOTES;
  return scopeApiInstance.post(url, formData)
    .then(async (res) => {
      if (res.data && res.data.message) {
        return Promise.resolve(res.data.message);
      } else {
        return Promise.reject()
      }
    })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getWorkFlowHistory = (page = 1, params = {}, abortController) => () => {
  return scopeApiInstance.get(JOB_BOARD_WORK_FLOW_HISTORY, { params: { page, ...params }, ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getExpenses = (abortController) => () => {
  return scopeApiInstance.get(JOB_BOARD_EXPENCES, { ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getPurchases = (params = {}, abortController) => () => {
  return scopeApiInstance.get(JOB_BOARD_PURCHASES, { params: params, ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const uploadInvoice = (formData) => () => {
  let url = JOB_BOARD_UPLOAD_INVOICE;
  return scopeApiInstance.post(url, formData).then(async (res) => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject()
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getPreviewPoData = (params = {}) => () => {
  let url = JOB_BOARD_PREVIEW_PO_DATA;
  return scopeApiInstance.get(url, { params: params }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}