import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "../JobStyle.module.css";
import FrequencyList from "./FrequencyList";
import WorkFlowHistory from "../JobBoard/WorkFlowHistory/WorkFlowHistory";
import v2Styles from "../../v2Styles.module.css";
import { getTaskFrequency } from "../../../../actions/v2/JobActions";
import { Drawer, Modal, notification } from "antd";
import { Strings } from "../../../../dataProvider/localize";
import { useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";
import AddFrequencyModal from "./AddFrequencyModal";

export default function TaskFrequencyContainer({ jobId, selectedTask, selectedJobDetails, setSelectedTask }) {

  const dispatch = useDispatch();
  const controller = useRef();

  const [historyKey, setHistoryKey] = useState(1);
  const [frequencies, setFrequencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addFreq, setAddFreq] = useState(false);
  const [showWorkflow, setShowWorkflow] = useState(false);

  useEffect(() => {
    fetchFrequencies();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  const refreshHistory = useCallback(() => {
    setHistoryKey(v => v + 1)
  }, [setHistoryKey])

  const fetchFrequencies = useCallback(() => {
    setLoading(true);
    controller.current = new AbortController();
    dispatch(getTaskFrequency({ signal: controller.current.signal }, { task_id: selectedTask.task_id, job_id: jobId })).then(res => {
      let allFreqs = res.taskFrequency.rows;
      allFreqs = allFreqs.map(x => ({
        ...x,
        contractor_details: x.contractor_id && x.resource_to ? [{ org_id: x.contractor_id, contractor_name: x.resource_to }] : null
      }))
      setFrequencies(allFreqs);
      setLoading(false);
    }).catch((err) => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: "There was an error while fetching Task Frequency Details.",
          className: "ant-error"
        })
      }
    })
  }, [selectedTask, jobId]);

  return (
    <div>
      <div className={`${styles.JobTasksListContainer} position-relative`} style={{ height: "calc(100vh - 280px)" }}>
        <div className="d-flex h-100 p-4" style={{ gap: "5px" }}>
          <div style={{ flex: "auto", overflow: "hidden auto", padding: "0px 5px 0px 0px" }}>
            <button
              onClick={() => setAddFreq(true)}
              disabled={loading || !selectedTask || (selectedTask && selectedTask.task_state && selectedTask.task_state.toString().toLowerCase() === "cancelled")}
              className={`${v2Styles.PrimaryButton}`}
              style={{
                position: "absolute",
                top: "-20px",
                right: "30px",
                display: showWorkflow ? "none" : "block"
              }}
            >
              Add Frequency
            </button>
            {selectedTask ?
              <FrequencyList
                jobId={jobId}
                setSelectedTask={setSelectedTask}
                loading={loading}
                fetchFrequencies={fetchFrequencies}
                frequencies={frequencies}
                selectedTask={selectedTask}
                selectedJobDetails={selectedJobDetails}
                refreshHistory={refreshHistory}
              /> : null}
          </div>
          <div className="border d-flex align-items-center justify-content-center" style={{ flex: "none", width: "40px", borderRadius: "5px", borderColor: "#ccc", flexDirection: "column" }}>
            <button
              onClick={(e) => {
                setShowWorkflow((v) => !v);
                e.preventDefault();
                e.stopPropagation();
              }}
              style={{ flex: "0 0 auto", padding: "10px 0px" }} className="bg-white text-dark">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={16} width={16}>
                <path fill="currentColor" d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" />
              </svg>
            </button>
            <button
              className="bg-white text-dark p-0"
              style={{
                flex: "1 1 auto",
                height: "auto",
                writingMode: "sideways-rl"
              }}
              onClick={(e) => {
                setShowWorkflow(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              Workflow History
            </button>
          </div>
          <Drawer
            className="worlflow-history-sidedrawer"
            placement="right"
            closable={false}
            onClose={() => setShowWorkflow(false)}
            visible={showWorkflow}
            destroyOnClose={true}
            getContainer={false}
            style={{ position: 'absolute' }}
            width={700}
            bodyStyle={{ padding: "0px" }}
            maskStyle={{
              borderRadius: "0px 0px 30px 30px"
            }}
          >
            <div>
              <WorkFlowHistory jobId={jobId} key={historyKey} onClose={() => setShowWorkflow(false)} />
            </div>
          </Drawer>
          {/* <motion.div
            layout
            animate={{
              // rotate: horizontal ? 0 : 90,
              visibility: showWorkflow ? 1 : 0,
              opacity: showWorkflow ? 1 : 0,
              x: showWorkflow ? 0 : 450,
            }}
            transition={{
              duration: 0.8,
              // delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            className="absolute bg-blue-400 w-screen  rounded-lg p-4 top-4 bottom-4"
          >
            <div
              className="h-8 rounded-lg bg-slate-400"
              onClick={() => {
                console.log("clicked");
                setShowWorkflow(false);
              }}
            ></div>
          </motion.div> */}
        </div>
        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-xl-7 col-lg-12 pr-0" style={{ }}>
             
            </div>
            <div className="col-xl-5 col-lg-12 pl-0">
              <div className="d-flex justify-content-end pt-2">
                <button
                  onClick={() => setAddFreq(true)}
                  disabled={loading || !selectedTask || (selectedTask && selectedTask.task_state && selectedTask.task_state.toString().toLowerCase() === "cancelled")}
                  className={`${v2Styles.PrimaryButton}`}
                >
                  Add Frequency
                </button>
              </div>
              <WorkFlowHistory jobId={jobId} key={historyKey} />
            </div>
          </div>
        </div> */}
      </div>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={addFreq}
        onCancel={() => setAddFreq(false)}
        afterClose={() => fetchFrequencies()}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        width="700px"
        closeIcon={<div className={v2Styles.ModalCloseIcon}><Close /></div>}
      >
        <AddFrequencyModal setSelectedTask={setSelectedTask} jobId={jobId} task={selectedTask} onClose={() => setAddFreq(false)} />
      </Modal>
    </div>
  )
}