import React, { Component, StrictMode } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators, compose } from "redux";
import { reduxForm } from "redux-form";
import { Collapse, Icon, Modal, notification } from "antd";
import { getStorage, handleFocus, capitalizeFirst } from "../../../../../utils/common";
import * as actions from "../../../../../actions/v2/JobDocumentActions";
import StaffTable from "./StaffTable/StaffTable";
import InsurancesTable from "../PreAllocate/InsurancesTable";
import ChemicalsTable from "../PreAllocate/ChemicalsTable";
import SchedulesTable from "./SchedulesTable";
import styles from "../PreAllocate/PreAllocateStyles.module.css";
import { serializePreviewRequest } from "../../serializers";
import TasksTable from "../PreAllocate/TasksTable";
import { loadJobDocument, loadPostAllocateData } from "../../synchronise";
import { Strings } from "../../../../../dataProvider/localize";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";
import StaffAllocate from "../PreAllocate/StaffAllocate";
import CloseIcon from '@mui/icons-material/Close';
import jobStyles from "../../../Job/JobStyle.module.css";
import EmailJobDoc from "../../EmailJobDoc";

const PreviewJobDocument = React.lazy(() => import("../../Preview/PreviewJobDocument"));
const { Panel } = Collapse;
export class PostAllocateContainer extends Component {
  state = {
    preview: false,
    editStaff: false,
    emailModal: false,
  };

  componentDidMount() {
    const { jobDocumentDetails, change, actions } = this.props;

    if (Object.keys(jobDocumentDetails).length) {
      loadPostAllocateData(
        jobDocumentDetails,
        this.props.certificates,
        this.props.additionalDocs,
        this.props.selectedAdditionalDocs,
        this.props.swmsDocument,
        this.props.jobDetails,
        this.props.chemicals,
        this.props.staffList,
        actions,
        change,
        this.props.organisations,
        this.props.isJobSwms
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.staffList !== prevProps.staffList) {
      if (Object.keys(this.props.jobDocumentDetails).length) {
        loadPostAllocateData(
          this.props.jobDocumentDetails,
          this.props.certificates,
          this.props.additionalDocs,
          this.props.selectedAdditionalDocs,
          this.props.swmsDocument,
          this.props.jobDetails,
          this.props.chemicals,
          this.props.staffList,
          this.props.actions,
          this.props.change,
          this.props.organisations,
          this.props.isJobSwms
        );
      }
    }
  }

  onSubmit = (values) => {
    const {
      jobDetails,
      jobDocumentDetails,
      additionalDocs,
      actions,
      routerParams,
      allocatedUsers,
      selectedJobDetails,
      organisations,
      isJobSwms,
      chemicals
    } = this.props;
    const serialized = serializePreviewRequest(
      values,
      jobDetails,
      jobDocumentDetails,
      additionalDocs,
      allocatedUsers,
      isJobSwms,
      chemicals
    );

    const currentOrganization = JSON.parse(getStorage(ADMIN_DETAILS))
      ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
      : null;

    // let totalSelectedUsers = 0;
    // serialized.job_allocated_users && serialized.job_allocated_users.forEach(user => {
    //   if (user.selected_for_job_doc === 1) {
    //     totalSelectedUsers++;
    //   }
    // })

    // if (totalSelectedUsers === 0) {
    //   notification.error({
    //     message: Strings.error_title,
    //     description: "Please select a staff first.",
    //     className: "ant-error"
    //   })
    // } else {
    actions
      .updateJobDocument(serialized)
      .then(async (res) => {
        notification.success({
          message: Strings.success_title,
          description:
            res && res.message ? res.message : "Job doc updated successfully",
          onClick: () => { },
          className: "ant-success",
        });

        this.setState({ preview: true });

        await actions.getJobDocumentDetails(jobDetails.job_id, selectedJobDetails.scopeId);
        await actions.getJobDocumentOrgCertificates(
          JSON.stringify(organisations && organisations.length ? organisations.map(org => org.id) : [currentOrganization]),
          jobDetails.job_id,
          organisations
        );
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
    // }
  };

  handleSelectColumn = (checked, typeId) => {
    this.props.allocatedUsers
      .forEach((user) => {
        let licences = user.user_licences.filter((lic) => lic.type.toString() === typeId.toString())
        licences.forEach(license => {
          this.props.change(`staff_cell_${user.user_name}_${license.id}`, checked)
        })
      })
  };

  synchronise = () => {
    const {
      routerParams,
      actions,
      staffList,
      certificates,
      additionalDocs,
      selectedAdditionalDocs,
      swmsDocument,
      jobDocumentDetails,
      chemicals,
      jobDetails,
      change,
      selectedJobDetails,
      organisations,
      isJobSwms
    } = this.props;

    loadJobDocument(routerParams.state, actions, selectedJobDetails, organisations);
    loadPostAllocateData(
      jobDocumentDetails,
      certificates,
      additionalDocs,
      selectedAdditionalDocs,
      swmsDocument,
      jobDetails,
      chemicals,
      staffList,
      actions,
      change,
      organisations,
      isJobSwms
    );
  };

  openJobDocEmail = () => {
    // this.props.actions.setEmailJobDetails(this.props.jobDetails)
    // this.props.history.push({
    //   pathname: this.props.match.params.jobId ? this.props.location.pathname + "/email-job-document" : "/dashboard/jobEmail/emailDocument",
    //   state: {
    //     job_doc_number: this.props.jobDocumentDetails.job_doc_number,
    //     quote_number: this.props.jobDetails.quote_number,
    //     client_id: this.props.jobDetails.client_id,
    //     org_id: this.props.jobDetails.org_id,
    //   },
    // });
    this.setState({
      emailModal: true,
    })
  };

  handleEditStaff = () => {
    this.props.onEditStaff(true);
  };

  handleUpdateStaffAllocation = () => {
    const {
      jobDocumentDetails,
      actions,
      staffList,
      jobDetails,
      routerParams,
      formValues: values,
      onShowModal,
      selectedJobDetails,
      organisations
    } = this.props;

    const jobDocTasks = [];
    let selectedStaffUserNames = [];
    const chemicals = [];
    const certificates = [];

    selectedStaffUserNames = values.selected_staffs ? values.selected_staffs.map(x => x.value) : [];

    Object.keys(values).forEach((key) => {
      // if (key.startsWith("select_staff_") && values[key]) {
      //   selectedStaffUserNames.push(+key.split("_")[2]);
      // }
      if (key.startsWith("select_task_") && values[key]) {
        jobDocTasks.push(+key.split("_")[2]);
      }
      if (key.startsWith("select_chemical_") && values[key]) {
        chemicals.push(+key.split("_")[2]);
      }
      if (key.startsWith("select_insurance_") && values[key]) {
        certificates.push(+key.split("_").pop());
      }
    });

    const selectedStaff = [];
    staffList.service_agents.forEach((sa) => {
      sa.site_supervisors.forEach((ss) => {
        if (selectedStaffUserNames.includes(ss.user_name)) {
          selectedStaff.push({
            org_id: sa.id,
            selected_for_job_doc: 1,
            user_name: ss.user_name,
            user_license: ss.user_licences.map((lic) => lic.id),
          });
        }
      });
      sa.staffs.forEach((st) => {
        if (selectedStaffUserNames.includes(st.user_name)) {
          selectedStaff.push({
            org_id: st.id,
            selected_for_job_doc: 1,
            user_name: st.user_name,
            user_license: st.user_licences.map((lic) => lic.id),
          });
        }
      });
    });

    if (selectedStaff.length === 0) {
      notification.error({
        message: Strings.error_title,
        description: "Atleast one staff is required.",
        className: "ant-error"
      })
    } else {

      const requestBody = {
        job_doc_id: jobDocumentDetails.id,
        job_doc_tasks: jobDocTasks,
        job_allocated_users: selectedStaff,
        job_number: jobDetails.job_number,
        client_id: jobDetails.client_id,
        quote_id: jobDetails.quote_id,
        job_id: jobDetails.job_id,
        certificates: certificates,
        chemicals: chemicals,
      };

      actions
        .createJobDocAllocation(requestBody, true)
        .then(async (message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-success",
          });
          const currentOrganization = JSON.parse(getStorage(ADMIN_DETAILS))
            ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
            : null;

          await actions.getJobDocumentDetails(jobDetails.job_id, selectedJobDetails.scopeId);
          await actions.getJobDocumentOrgCertificates(
            JSON.stringify(organisations && organisations.length ? organisations.map(org => org.id) : [currentOrganization]),
            jobDetails.job_id,
            organisations
          );
          console.log(jobDetails);
          await actions.getJobDocumentDetails(jobDetails.job_id, selectedJobDetails.scopeId);
          this.synchronise();
          this.props.onEditStaff(false);
          Object.keys(this.props.formValues)
            .filter(
              (key) => key === "select_sa" || key.startsWith("select_staff_")
            )
            .forEach((keyName) => delete this.props.formValues[keyName]);
        })
        .catch((message) =>
          notification.error({
            message: Strings.error_title,
            description: message
              ? Array.isArray(message)
                ? message[0].message
                : Strings.generic_error
              : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          })
        );
    }
  };

  cancelEdit = (e) => {
    e.stopPropagation();
    this.props.onEditStaff(false);
    Object.keys(this.props.formValues)
      .filter((key) => key === "select_sa" || key.startsWith("select_staff_"))
      .forEach((keyName) => delete this.props.formValues[keyName]);
  };

  render() {
    const {
      jobDetails,
      certificates,
      additionalDocs,
      swmsDocument,
      jobDocumentDetails,
      jobDocumentCreatorDetails,
      handleSubmit,
      formValues,
      allocatedUsers,
      licenseTypes,
      chemicals,
      allocatedTasks,
      change,
      staffList,
      onShowModal,
    } = this.props;

    return (
      <div className={styles.DocsContainer}>
        {/* <Header
          jobDetails={jobDetails}
          jobDocumentCreatorDetails={jobDocumentCreatorDetails}
          synchronise={this.synchronise}
        /> */}
        <form onSubmit={handleSubmit(this.onSubmit)}>
          {Object.keys(jobDocumentDetails).length && allocatedTasks.length ? (
            <section className="px-2 mb-1">
              <div>
                <TasksTable
                  fromPostAllocation={true}
                  allocatedTasks={allocatedTasks}
                  sites={jobDetails.sites ? jobDetails.sites : []}
                  jobDetails={jobDetails}
                />
              </div>
            </section>
          ) : ''}

          {certificates && Object.keys(certificates).length ? (
            <section className="px-2 mb-1">
              <div>
                <div className={jobStyles.SectionHeading}>Insurance & OHS Documents</div>
                <div className={`${jobStyles.card} py-2`}>
                  <Collapse bordered={false} className="custom-section-v2-collapse" expandIconPosition="right" expandIcon={({ isActive }) => (
                    <Icon
                      style={{ color: "#2f1f41" }}
                      type="caret-right"
                      rotate={isActive ? 90 : 0}
                    />
                  )}>
                    {certificates.map((org, index) => org.certificates ? (
                      <Panel
                        style={{ border: '0px !important', marginTop: index === 0 ? "0px" : "10px" }}
                        header={
                          <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading font-weight-normal`}>{`${capitalizeFirst(org.name, true)} ${org.isOrg ? '(Org)' : ''}`}</div>
                        }
                        key={`certificate_panel_${org.id}`}
                        className={`custom-swms-category-header ${jobStyles.card}`}>
                        <InsurancesTable
                          licenses={org.certificates && org.certificates.length ? org.certificates : []}
                          additionalDocs={additionalDocs && additionalDocs.length ? additionalDocs.filter(doc => doc.contractor_id.toString() === org.id.toString()) : []}
                          orgId={org.id}
                          jobId={this.props.jobDetails.job_id}
                          swmsDocument={swmsDocument && swmsDocument.length ? swmsDocument.filter(x => x.organisation_id.toString() === org.id.toString()) : []}
                          change={change}
                          formValues={formValues}
                          jobDetails={jobDetails}
                          onShowModal={onShowModal}
                        />
                      </Panel>
                    ) : '')}
                  </Collapse>
                </div>
              </div>
            </section>
          ) : ''}


          {jobDocumentDetails && Object.keys(jobDocumentDetails).length && chemicals.length ? (
            <section className="px-2 mb-1">
              <div>
                <div className={jobStyles.card}>
                  <Collapse bordered={false} className="custom-section-v2-collapse" expandIconPosition="right" expandIcon={({ isActive }) => (
                    <Icon
                      style={{ color: "#2f1f41" }}
                      type="caret-right"
                      rotate={isActive ? 90 : 0}
                    />
                  )}>
                    <Panel
                      style={{ border: '0px !important' }}
                      header={
                        <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading font-weight-normal`}>Chemical MSDS</div>
                      }
                      className={`custom-swms-category-header ${jobStyles.card}`}>
                      <ChemicalsTable
                        isPostAllocate
                        allocatedChemicals={jobDocumentDetails.job_docs_chemicals}
                        onShowModal={onShowModal}
                      />
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </section>) : ''}

          {Object.keys(jobDocumentDetails).length ? (
            <section className="px-2 mb-1">
              <div>
                <div className={`custom-swms-category-header ${jobStyles.card}`}>
                  <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading font-weight-normal`}>
                    <div className="d-flex align-items-center justify-content-between"><div>Staff</div>
                      <div className="text-right">
                        {this.props.editStaff ? (
                          <button className={styles.SimpleButton} type="button" onClick={(e) => { e.stopPropagation(); this.cancelEdit(e) }}>Cancel</button>) : (
                          <button className={styles.SimpleButton} type="button" onClick={(e) => { e.stopPropagation(); this.handleEditStaff(); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "12px", widt: "12px" }}>
                              <path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </button>)
                        }
                      </div>
                    </div>
                  </div>
                  <div className={`${jobStyles.OpenBodyBorder} p-2`}>
                    {this.props.editStaff ? (
                      <StaffAllocate
                        staffList={staffList}
                        formValues={formValues}
                        updating
                        editStaff={this.props.editStaff}
                        onEditStaff={this.props.onEditStaff}
                        change={this.props.change} />) : (
                      <StaffTable
                        allocatedUsers={allocatedUsers}
                        licenseTypes={licenseTypes}
                        onHeaderCheck={this.handleSelectColumn}
                        onCellCheck={this.handleCellCheck}
                        formValues={formValues}
                        change={change}
                        onShowModal={onShowModal}
                      />)
                    }
                  </div>
                </div>
              </div>
            </section>
          ) : ''}

          {jobDocumentDetails && Object.keys(jobDocumentDetails).length ? (
            <section className="px-2">
              <div>
                <div className={`custom-swms-category-header ${jobStyles.card}`}>
                  <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading font-weight-normal`}>
                    <div className="d-flex align-items-center justify-content-between"><div>Schedule</div></div>
                  </div>
                  <div className={`${jobStyles.OpenBodyBorder} py-2`}>
                    <SchedulesTable
                      jobDocumentDetails={jobDocumentDetails}
                      formValues={formValues}
                    />
                  </div>
                </div>
              </div>
            </section>
          ) : ''}


          <section className="px-2 my-4">
            <div className="d-flex justify-content-center">
              {this.props.editStaff ? (
                <button
                  type="button"
                  className={`${jobStyles.SecondaryButton}`}
                  onClick={this.handleUpdateStaffAllocation}
                >
                  Update Allocation
                </button>
              ) : (
                <button
                  type="submit"
                  className={`${jobStyles.PrimaryButton}`}
                >
                  Preview & Email
                </button>
              )}
            </div>
          </section>
        </form>
        {
          this.state.preview && (
            <Modal
              className="cs-modal-v2"
              visible={this.state.preview}
              width="80vw"
              onCancel={() => this.setState({ preview: false })}
              closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
              footer={[
                <div style={{ maxWidth: "1440px", margin: "0px auto 20px" }}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <button
                          key="back"
                          className={jobStyles.FilterResetButton}
                          onClick={() => this.setState({ preview: false })}
                        >
                          Cancel
                        </button>
                        <button
                          className={jobStyles.PrimaryButton}
                          onClick={this.openJobDocEmail}
                        >
                          Email Job Document
                        </button>
                      </div>
                    </div>
                  </div>
                </div>,
              ]}
            >
              <StrictMode>
                <React.Suspense fallback={<span>Loading...</span>}>
                  <div>
                    <PreviewJobDocument
                      jobid={jobDetails.job_id}
                      orgid={jobDetails.org_id}
                    />
                  </div>
                </React.Suspense>
              </StrictMode>
            </Modal>
          )
        }
        <Modal
          className="cs-modal-v2"
          visible={this.state.emailModal}
          width="768px"
          centered
          bodyStyle={{ paddingRight: "0px", paddingLeft: "0px", maxHeight: "80vh", overflow: "hidden auto" }}
          footer={null}
          destroyOnClose={true}
          onCancel={() => this.setState({ emailModal: false })}
          closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
        >
          <EmailJobDoc
            jobDocumentDetails={jobDocumentDetails}
            jobDetails={jobDetails} onClose={() => this.setState({ emailModal: false })} />
        </Modal>
      </div >
    );
  }
}

const mapStateToProps = (state) => {

  let allCertificates = state.JobDocument.certificates ? state.JobDocument.certificates : [];
  let jobCertificates = state.JobDocument.jobDocumentDetails && state.JobDocument.jobDocumentDetails.job_doc_orgs_certificates ? state.JobDocument.jobDocumentDetails.job_doc_orgs_certificates : [];
  let newCerts = [];
  if (allCertificates.length > 0) {
    if (jobCertificates.length > 0) {
      newCerts = [...allCertificates.map(org => ({ ...org, certificates: org.certificates.filter(x => x.active === 1 || jobCertificates.find(oldCert => oldCert.orgs_certificate_id === x.id)) }))]
    } else {
      newCerts = [...allCertificates.map(org => ({ ...org, certificates: org.certificates.filter(x => x.active === 1) }))]
    }
  }

  return {
    staffList: state.JobDocument.staffList,
    chemicals: state.JobDocument.chemicals,
    jobDetails: state.JobDocument.jobDetails,
    certificates: newCerts,
    additionalDocs: state.JobDocument.additionalDocs,
    swmsDocument: state.JobDocument.swmsDocument,
    selectedAdditionalDocs: state.JobDocument.selectedAdditionalDocs,
    jobSwmsDoc: state.JobDocument.jobSwmsDoc,
    licenseTypes: state.JobDocument.licenseTypes,
    jobDocumentDetails: state.JobDocument.jobDocumentDetails,
    jobDocumentCreatorDetails: state.JobDocument.jobDocumentCreatorDetails,
    initialValues: state.JobDocument.initialValues,
    allocatedUsers: state.JobDocument.allocatedUsers,
    allocatedTasks: state.JobDocument.allocatedTasks,
    organisations: state.JobDocument.organisations,
    isJobSwms: state.JobDocument.isJobSwms,
    formValues:
      state.form &&
        state.form.PostAllocateContainer &&
        state.form.PostAllocateContainer.values
        ? state.form.PostAllocateContainer.values
        : {},
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "PostAllocateContainer",
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
  })
)(PostAllocateContainer);
