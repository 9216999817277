import React, { useEffect, useRef, useState } from "react";
import styles from "./JobStyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getJobHeader } from "../../../actions/v2/JobActions"
import { currencyFormat } from "../../../utils/common";
import PurchaseOrder from "./JobBoard/PurchaseOrder";
import { Popover } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Header(props) {

  const dispatch = useDispatch();
  const headerDetails = useSelector((state) => state.Job.jobHeader)

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (props.jobId) {
      dispatch(
        getJobHeader(props.jobId)
      );
    }
  }, [props.jobId])

  const container = useRef(null);

  return (
    <div ref={container}>
      <button className={`${styles.PrimaryButton} d-flex align-items-center`} onClick={() => setOpen(true)}>
        Info<KeyboardArrowDownIcon />
      </button>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        anchorEl={container.current}
        style={{ zIndex: 999 }}
        onClose={() => setOpen(false)}>
        <div className={`${styles.HeaderContainer} ${styles.shadowed}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-3  border-right">
                {/* <div className="font-weight-bold">Quote</div>
                <div className="font-weight-normal">
                  <div>
                    Quote Id -
                    <span className="mx-1">
                      {headerDetails && headerDetails.quote_label && headerDetails.quote_label}
                    </span>
                  </div>
                  <div>
                    Quote Created By -
                    <span className="mx-1">
                      {headerDetails && headerDetails.quote_creator_name && headerDetails.quote_creator_name}
                    </span>
                  </div> */}
                <PurchaseOrder jobId={props.jobId} headerDetails={headerDetails} />
              </div>
              <div className="col-3 border-right">
                <div className="font-weight-bold">Job</div>
                <div className="font-weight-normal">
                  <div>
                    <span className="font-weight-bold">
                      Job Id -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.job_label && headerDetails.job_label}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-bold">
                      Job Created By -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.job_creator_name && headerDetails.job_creator_name}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-bold">
                      Job Allocated To -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.account_managers && headerDetails.account_managers.length ? headerDetails.account_managers.map(x => x.full_name).join(", ") : ""}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-bold">
                      Budget -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.job_outsourced_budget && currencyFormat(headerDetails.job_outsourced_budget)}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-bold">
                      Allocated Budget -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.job_allocated_budget && currencyFormat(headerDetails.job_allocated_budget)}
                    </span>
                  </div>
                  {/* <div>
                Outsourced To -
                <span className="mx-1">
                  {headerDetails && headerDetails.job_outsourced_to_name && headerDetails.job_outsourced_to_name}
                </span>
              </div> */}
                </div>
              </div>
              <div className="col-3 border-right">
                <div className="font-weight-bold">Client</div>
                <div className="font-weight-normal">
                  <div>
                    <span className="font-weight-bold">
                      Client Company -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.client_name && headerDetails.client_name}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-bold">
                      Client Contact -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.client_contact_name && headerDetails.client_contact_name}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-bold">
                      Client Email -
                    </span>
                    <span className="mx-1 text-break">
                      {headerDetails && headerDetails.client_contact_email && headerDetails.client_contact_email}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-bold">
                      Client Phone -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.client_contact_phone && headerDetails.client_contact_phone}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="font-weight-bold">Site</div>
                <div className="font-weight-normal">
                  <div>
                    <span className="font-weight-bold">
                      Site Name -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.site_name && headerDetails.site_name}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-bold">
                      Site Address -
                    </span>
                    <span className="mx-1">
                      {headerDetails && headerDetails.site_address && headerDetails.site_address}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popover >
    </div >

  );
}