import React, { useState } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { CircularProgress } from "@mui/material";
import { notification } from "antd";
import { components } from "react-select";
import { useDispatch } from "react-redux";

import { customInput } from "../../../common/custom-input";
import { CustomSingleSelect } from "../../../common/new-core-ui/CustomSingleSelect";
import { customTextarea } from "../../../common/customTextarea";
import { addUpdateJobReport } from "../../../../actions/SAJobMgmtAction";
import { Strings } from "../../../../dataProvider/localize";

import SelectV2SingleStyle from "../../Job/SelectV2SingleStyle";
import ReportFilesContainer from "./ReportFilesContainer";
import ReportVideoField from "./ReportVideoField";

import styles from "../../v2Styles.module.css";

const validate = values => {
  const errors = {}
  if (!values.quote_task_id) {
    errors.quote_task_id = 'Required'
  }
  if (!values.area) {
    errors.area = 'Required'
  }
  if (!values.files || (values.files && !values.files.length)) {
    errors.files = "Required";
  }
  return errors
}

const AddEditReportRecord = ({ mode, onClose, tasks, handleSubmit, jobId }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = (formValues) => {

    if (!formValues.files || !formValues.files.length) {
      notification.error({
        message: Strings.error_title,
        description: 'Please add at least one photo to continue.',
        className: "ant-error",
      })
    } else {
      setLoading(true);
      let uploading = false;
      let data = {};
      let quote_task_id = formValues.quote_task_id.value;
      let task = tasks.find(x => x.id === quote_task_id);
      let values = { quote_task_id: quote_task_id, job_id: jobId, area: formValues.area, note: formValues.note };
      if (task && task.task_id) {
        values.task_id = task.task_id
      }
      let allFiles = [];
      formValues.files.forEach(file => {
        if (file.file_name && file.file_name.isUploaded) {
          allFiles.push({
            file_name: file.file_name.file_name,
            type: "Photo",
            ...(file.file_name.visible_name ? { visible_name: file.file_name.visible_name } : {})
          })
        } else {
          uploading = true;
        }
      });

      if (formValues.video) {
        if (formValues.video && formValues.video.isUploaded && formValues.video.file_name) {
          allFiles.push({
            file_name: formValues.video.file_name,
            type: "Video",
            ...(formValues.video.visible_name ? { visible_name: formValues.video.visible_name } : {})
          })
        } else {
          uploading = true;
        }
      }
      if (uploading) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: "One or more files are still uploading or they failed to upload.",
          className: "ant-error"
        });
      } else {
        values.files = [...allFiles];
        data.job_cleaning_reports = [values];
        data.job_id = jobId;
        data.job_cleaning_reports_documents = [];
        if (mode === "Edit") {
          data.id = formValues.id
        }
        dispatch(addUpdateJobReport(data, mode === "Edit" ? false : true)).then(res => {
          setLoading(false);
          notification.success({
            message: Strings.success_title,
            description: res,
            className: "ant-success",
          });
          onClose();
        }).catch(error => {
          if (error) {
            setLoading(false);
            notification.error({
              message: Strings.error_title,
              description: error ? error : Strings.generic_error,
              className: "ant-error",
            });
          }
        })
      }
    }
  }


  return (
    <div className="v2-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>{mode === "Edit" ? `Edit Record` : "Add New Record"}</div>
            <div className="">
              <hr />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-6 pt-2">
              <div>
                <div className={styles.Label}>Task</div>
                <Field
                  className="w-100"
                  name="quote_task_id"
                  component={CustomSingleSelect}
                  styles={SelectV2SingleStyle}
                  otherProps={{
                    components: { dropdownIndicator: <components.DownChevron /> }
                  }}
                  options={
                    tasks &&
                    tasks.map((tsk) => ({
                      label: `${tsk.task_name}${tsk.area_name ? " - " + tsk.area_name : ""}`,
                      value: tsk.id,
                    }))
                  }
                />
              </div>
            </div>
            <div className="col-6 pt-2">
              <div>
                <div className={styles.Label}>Area</div>
                <Field
                  component={customInput}
                  name="area"
                  disabled={loading}
                  autoComplete="off"
                  autoFocus
                  className={styles.TextField}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pt-2">
              <FieldArray
                name="files"
                loading={false}
                // previewFile={openFilePreview}
                formName={"AddEditReportRecord"}
                component={ReportFilesContainer}
                s3UploadPath={`job_reports`}
                fieldName={"files"}
              />
            </div>
            <div className="col-6 pt-2">
              <div className={styles.Label}>Video</div>
              <Field
                name="video"
                disabled={loading}
                component={ReportVideoField}
                s3UploadPath={"job_reports"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 pt-2">
              <div className={styles.Label}>Note</div>
              <Field name={`note`} rows={3} component={customTextarea} className={styles.TextArea} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right py-3">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
                {loading ? (
                  <CircularProgress size={20} />) : ""}
                <button type="button" className={`${styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className={styles.PrimaryButton}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  )
}

export default reduxForm({
  form: "AddEditReportRecord",
  validate,
  destroyOnUnmount: true,
})(AddEditReportRecord);