import React, { useEffect, useState } from "react";
import { currencyFormat } from "../../../../../utils/common";
import styles from "../../JobStyle.module.css";
import TaskAdjustment from "./TaskAdjustment";

export default function Task(props) {

  const [freqDetails, setFreqDetails] = useState({});
  const [estimate, setEstimate] = useState({});
  const [addNewTask, setAddNewTask] = useState(false);
  const [total, setTotal] = useState("$0.00");
  const [invoiceStatus, setInvoiceStatus] = useState(0);

  const getInitialValues = (values) => {
    return {
      ...values,
      ...(props.taskDetails && props.taskDetails.task_id ? { task_id: props.taskDetails.task_id } : {}),
      adjustment_cost: values.adjustment_cost.toString(),
      freq_id: props.frequency && props.frequency.quote_task_id ? props.frequency.quote_task_id : null,
      freq_cost: props.frequency && props.frequency.task_amount ? props.frequency.task_amount : null,
    }
  }

  useEffect(() => {
    if (props.frequency) {
      setFreqDetails(props.frequency);
      let type = props.taskDetails.estimate && props.taskDetails.estimate.estimate_type ? props.taskDetails.estimate.estimate_type : null;
      if (props.frequency.task_cost_adjustments && props.frequency.task_cost_adjustments.length === 0) {
        setAddNewTask(true);
      }
      let total = "$0.00";
      if (type !== null) {
        let details = {}
        switch (type) {
          case "area": details = {
            "SQM": props.taskDetails.estimate.sqm,
            "Rate": currencyFormat(props.taskDetails.estimate.rate),
          }
          total = currencyFormat(props.taskDetails.estimate.sqm *  props.taskDetails.estimate.rate)
            break;
          case "hours":
            details = {
              "Days": props.taskDetails.estimate.days,
              "Staff": props.taskDetails.estimate.staff,
              "Hours": props.taskDetails.estimate.hours,
              "Rate": currencyFormat(props.taskDetails.estimate.rate),
            }
            total = currencyFormat(props.taskDetails.estimate.days *  props.taskDetails.estimate.staff * props.taskDetails.estimate.hours * props.taskDetails.estimate.rate)
            break;
          case "quant":
            details = {
              "Quantity": props.taskDetails.estimate.quant,
              "Rate": currencyFormat(props.taskDetails.estimate.rate),
            }
            total = currencyFormat(props.taskDetails.estimate.quant * props.taskDetails.estimate.rate)
            break;
          default: details = {};
        }
        setTotal(total);
        setEstimate(details);
      }
    }
    let status = 0;
    if (props.taskDetails.invoice_number && props.taskDetails.invoice_number !== null) {
      status = 3;
    } else if (props.taskDetails.job_board_task_state && props.taskDetails.job_board_task_state.toLowerCase() === "values approved for invoice") {
      status = 2;
    } else if ((props.taskDetails.job_board_task_state && props.taskDetails.job_board_task_state.toLowerCase() === "confirm values approved for invoice") || props.taskDetails.task_state.toLowerCase() === "completed" || props.taskDetails.task_state.toLowerCase() === "signed off") {
      status = 1;
    }
    setInvoiceStatus(status);
  }, [props.frequency, props.taskDetails])

  const toggleAddNewTask = () => {
    setAddNewTask(!addNewTask);
  }

  return (
    <div className={`container-fluid px-0`}>
      <div className="row">
        {/* {Object.keys(estimate).length > 0 && <div className="col-3 pr-0">
          <div className={styles.card}>
            <div className="">
              {Object.entries(estimate).map(([key, value]) => (
                <div className="d-flex justify-content-between" key={key}>
                  <div className={`${styles.PrimaryText} font-weight-bold`}>
                    {key}
                  </div>
                  <div className="font-weight-bold">{value}</div>
                </div>
              ))}
            </div>
            <hr className={styles.greyHR} />
            <div className="d-flex justify-content-between">
              <div className={`${styles.PrimaryText} font-weight-bold`}>Total</div>
              <div className="font-weight-bold">{total}</div>
            </div>
          </div>
        </div>} */}
        {props.taskDetails && props.taskDetails.additional_cost_details && props.taskDetails.additional_cost_details.length > 0 &&
          <div className="col-12 pr-0">
            <div className={styles.card}>
              <div className="">
                <div className="d-flex justify-content-between">
                  <div className={`${styles.PrimaryText}`}>
                    Additional Cost
                  </div>
                  <div className={`${styles.PrimaryText}`}>
                    Total
                  </div>
                </div>
                {freqDetails.additional_cost_details && freqDetails.additional_cost_details.map(cost => (
                  <div className={`${styles.PrimaryText} d-flex justify-content-between`} key={cost.additional_cost_name}>
                    <div className="font-weight-bold">{cost.additional_cost_name}</div>
                    <div className="font-weight-bold">{currencyFormat(cost.additional_cost)}</div>
                  </div>
                ))}
              </div>
              {freqDetails.task_adjustment_cost && freqDetails.task_adjustment_cost !== 0 ? (
                <>
                  <hr className={styles.greyHR} />
                  <div className="p-2">
                    <div className={`${styles.PrimaryText} font-weight-bold`}>Task Adjustment</div>
                    <div className="font-weight-bold">{freqDetails.task_adjustment_cost ? currencyFormat(freqDetails.task_adjustment_cost) : ''}</div>
                  </div>
                </>
              ) : ''}
            </div>
          </div>
        }
        <div className={`col-12`}>
          <div className={styles.card}>
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-5">
                  <div className={`${styles.CommonLabel} font-weight-bold`}>New Adjustment</div>
                </div>
                <div className="col-3 px-0">
                  <div className={`${styles.CommonLabel} font-weight-bold`}>Adjustment Total</div>
                </div>
              </div>
            </div>
            {freqDetails.task_cost_adjustments && freqDetails.task_cost_adjustments.map((additional_cost, index) => (
              <TaskAdjustment
                adjustment={additional_cost}
                initialValues={getInitialValues(additional_cost)}
                taskDetails={props.taskDetails}
                form={`additionalTask-${additional_cost.id}`}
                key={additional_cost.id}
                jobId={props.jobId}
                showAddOption={(index + 1) === freqDetails.task_cost_adjustments.length ? true : false}
                onNewTaskToggleClick={() => toggleAddNewTask()}
                invoiceStatus={invoiceStatus}
                isDisabledJob={props.isDisabledJob}
                frequency={props.frequency}
                fetchFrequencies={props.fetchFrequencies}
              />
            ))}
            {addNewTask && invoiceStatus !== 3 &&
              <TaskAdjustment
                adjustment={{}}
                jobId={props.jobId}
                initialValues={{
                  task_id: props.taskDetails.task_id, job_id: props.jobId,
                  freq_id: props.frequency && props.frequency.quote_task_id ? props.frequency.quote_task_id : null,
                  freq_cost: props.frequency && props.frequency.task_amount ? props.frequency.task_amount : null,
                }}
                taskDetails={props.taskDetails}
                form={`newAdditionalTask-${freqDetails.quote_task_id}`}
                onNewTaskToggleClick={() => toggleAddNewTask()}
                isDisabledJob={props.isDisabledJob}
                frequency={props.frequency}
                fetchFrequencies={props.fetchFrequencies}
              />
            }
            {freqDetails.task_adjustment_total_cost && freqDetails.task_adjustment_total_cost !== 0 ?
              <div className="row">
                <div className="col-12">
                  <hr className={styles.greyHR} style={{ height: "1px" }} />
                  <div className="">
                    <div className="container py-0">
                      <div className="row">
                        <div className="col-5 px-0">
                          <div className={`${styles.PrimaryText} font-weight-bold`}>Total Adjusted</div>
                        </div>
                        <div className="col-5 px-0">
                          <div className="font-weight-bold">{currencyFormat(freqDetails.task_adjustment_total_cost)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
}
