import React, { useCallback, useRef, useState, useEffect } from "react";
import styles from "../JobStyle.module.css";
import { currencyFormat } from "../../../../utils/common";
import dayjs from "dayjs";
import { IconButton, Tooltip } from "@mui/material";
import { Modal, notification, Popconfirm } from "antd";
import Select, { components } from "react-select";
import { JobBoardStatusSelectStyle } from "../SelectV2SingleStyle";
import { useDispatch } from "react-redux";
import { updateFrequencyStatus } from "../../../../actions/v2/JobActions";
import { Strings } from "../../../../dataProvider/localize";
import FrequencyShiftsModal from "./FrequencyShiftsModal";
import ExtendFrequencyModal from "./ExtendFrequencyModal";
import CloseIcon from '@mui/icons-material/Close';
import { capitalizeFirst } from "../../../../utils/common";
import v2Styles from "../../v2Styles.module.css";

export default function FrequencyHeader({ frequency, onActive, selectedTask, jobId, fetchFrequencies, refreshHistory, selectedJobDetails, setSelectedTask, getNextFrequency }) {
  const [shiftsModal, setShiftsModal] = useState(false);
  const [extendFreq, setExtendFreq] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [frequencyState, setFrequencyState] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const dispatch = useDispatch();

  const frequencyStates = ["Booked", "In Progress", "Completed"];

  const controller = useRef();

  const isDisabledState = useCallback((state) => {
    let availableStates = [];
    let currentState = frequency && frequency.freq_status ? frequency.freq_status : "";

    switch (currentState) {
      case "Booked":
        availableStates.push("In Progress", "Completed");
        break;
      case "In Progress":
        availableStates.push("Completed")
        break;
      case "Completed":
        availableStates.push("In Progress");
        break;
      // case "Signed Off":
      //   availableStates.push("In Progress", "Completed");
      //   break;
      case "":
        availableStates = [];
        break;
      default: availableStates.push("Booked");
    }
    return state !== currentState && !availableStates.includes(state)
  }, [frequency]);

  const onFrequencyStateChange = useCallback((e) => {
    setFrequencyState(e.value);
    setVisible(true);
  }, [setFrequencyState, setVisible])

  const cancelFrequencyStateChange = useCallback(() => {
    setVisible(false)
    if (frequency && frequency.freq_status) {
      setFrequencyState(frequency.freq_status);
    } else {
      setFrequencyState("Booked");
    }
  }, [setFrequencyState, frequency])

  const onFrequencyStatusChange = useCallback((status) => {
    setLoading(true);
    let formData = {
      status: status,
      quote_task_label: frequency.quote_task_label,
      task_id: selectedTask.task_id,
      job_id: jobId,
      quote_task_id: frequency.quote_task_id,
      ...(frequency.task_invoice_id ? { task_invoice_id: frequency.task_invoice_id } : {}),
      ...(frequency.contractor_task_invoice_id ? { contractor_task_invoice_id: frequency.contractor_task_invoice_id } : {}),
      ...(frequency.assignee ? { assignee_id: frequency.assignee } : {})
    }
    dispatch(updateFrequencyStatus(formData)).then(message => {
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      });
      setVisible(false);
      fetchFrequencies();
      refreshHistory();
    }).catch(err => {
      setVisible(false);
      cancelFrequencyStateChange();
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: "ant-error"
      })
    }).finally(() => {
      setLoading(false);
    });
  }, [frequency, setLoading, dispatch, updateFrequencyStatus, setVisible, fetchFrequencies, refreshHistory, cancelFrequencyStateChange])


  useEffect(() => {
    if (frequency && frequency.freq_status) {
      setFrequencyState(frequency.freq_status);
    } else {
      setFrequencyState("Booked");
    }
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, [frequency])

  const onExtendClose = () => {
    setExtendFreq(false);
  }

  return (
    <div className={`${styles.TaskHeader}`} onClick={() => onActive()}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <table className="table table-sm cs-table cs-table-v2 no-bottom-border job-board-freq-table"
              style={{ tableLayout: "fixed", width: "100%" }}>
              <thead>
                <tr>
                  <th className="align-middle" width="8%">Task ID</th>
                  <th width="20px" className="p-0"></th>
                  <th className="align-middle">Task Name</th>
                  <th className="align-middle">Area Name</th>
                  <th className="text-center align-middle" width="8%">Start Date</th>
                  <th className="pr-0 align-middle text-center" width="8%">End Date</th>
                  <th className="align-middle" style={{ width: "70px" }}></th>
                  <th className="align-middle" width="12%">Status</th>
                  <th width="8%" className="text-center align-middle">Task Frequency Value</th>
                  <th className="align-middle text-center">Assigned To</th>
                  <th className="align-middle text-center">Resourced To</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-top">{frequency.quote_task_label}</td>
                  <td className="align-top" width="25px">
                    <div className="d-flex align-items-center justify-items-start" style={{ gap: "10px" }}>
                      <Tooltip title="View Task Details">
                        <IconButton className="p-0 mb-1" style={{ height: "14px", width: "14px" }} onClick={(e) => { e.stopPropagation(); setShowDetails(true) }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="16px" className={styles.DarkIcon}>
                            <path fill="#362148" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                          </svg>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </td>
                  <td className="align-top">{frequency.task_name}</td>
                  <td className="align-top">{frequency.area_name}</td>
                  <td className="text-center align-top">{dayjs(frequency.start_date).format("DD/MM/YYYY")}</td>
                  <td className="pr-0 text-center align-top">{dayjs(frequency.end_date).format("DD/MM/YYYY")}</td>
                  <td className="pl-0 align-top">
                    <div className="d-flex align-items-center justify-items-start" style={{ gap: "10px" }}>
                      {frequency.freq_status && frequency.freq_status.toString().toLowerCase() !== "cancelled" ? (<>
                        <Tooltip title="Extend Frequency">
                          <IconButton className="p-0 mb-1" style={{ height: "14px", width: "14px" }} onClick={(e) => { setExtendFreq(true); e.stopPropagation(); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={styles.SuccessIcon} >
                              <path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                            </svg>
                          </IconButton>
                        </Tooltip>
                        {frequency.extend_freq_type ? (
                          <Tooltip title="Create Shifts">
                            <IconButton className="p-0 mb-1" sx={{ height: "auto", color: "#c0b4ff", fontSize: "20px" }} size="small" onClick={(e) => { setShiftsModal(true); e.stopPropagation(); }}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="16px">
                                <path fill="currentColor" d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                              </svg>
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </>) : null}
                    </div>
                  </td>
                  <td className="text-center align-top" onClick={(e) => { e.stopPropagation(); }}>
                    <Popconfirm
                      title={<div>
                        <div className="font-weight-bold">Are you sure you want to change Status for this Frequency?</div>
                        <div>Status Changes are not reversible.</div>
                      </div>}
                      visible={visible}
                      onCancel={() => cancelFrequencyStateChange()}
                      onConfirm={() => onFrequencyStatusChange(frequencyState)}
                    >
                      <Select
                        value={{ label: frequencyState, value: frequencyState }}
                        className={`w-100 text-${frequencyState ? frequencyState.toString().replace(/\s/gm, '').toLowerCase() : ""}`}
                        onChange={onFrequencyStateChange}
                        isDisabled={frequencyState.toString().toLowerCase() === "cancelled"}
                        options={frequencyStates.map(state => ({
                          label: state, value: state,
                          isDisabled: isDisabledState(state)
                        }))}
                        styles={JobBoardStatusSelectStyle}
                        otherProps={{
                          components: { dropdownIndicator: <components.DownChevron /> }
                        }}
                      />
                    </Popconfirm>
                  </td>
                  <td className="align-top text-center"><div className={frequency.freq_status && frequency.freq_status.toString().toLowerCase() === "cancelled" ? styles.redLineThrough : ""}>
                    {frequency && currencyFormat(frequency.task_amount) ? currencyFormat(frequency.task_amount) : " - "}
                  </div>
                  </td>
                  <td className="align-top text-center">
                    {frequency && frequency.assigned_to ? frequency.assigned_to : " - "}
                  </td>
                  <td className="align-top text-center">
                    {frequency && frequency.resource_to ? frequency.resource_to : " - "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <Modal
            className="cs-modal-v2"
            centered
            footer={null}
            visible={extendFreq}
            onCancel={() => setExtendFreq(false)}
            afterClose={() => fetchFrequencies()}
            maskClosable={false}
            bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
            destroyOnClose={true}
            width="700px"
            closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
          >
            <ExtendFrequencyModal
              initialValues={{
                start_date: frequency.start_date,
                end_date: dayjs(frequency.end_date).add(1, "day"),
                freq_type: frequency.freq_type ? frequency.freq_type.toLowerCase() === "none" ? "Does not repeat" : capitalizeFirst(frequency.freq_type, true) : ""
              }}
              form={`extend_frequency_${frequency.quote_task_id}`}
              frequency={frequency}
              task={selectedTask}
              setSelectedTask={setSelectedTask}
              getNextFrequency={getNextFrequency}
              selectedJobDetails={selectedJobDetails}
              onClose={onExtendClose}
            />
          </Modal>
          <Modal
            className="cs-modal-v2"
            centered
            footer={null}
            visible={shiftsModal}
            afterClose={() => fetchFrequencies()}
            onCancel={() => setShiftsModal(false)}
            maskClosable={false}
            bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
            destroyOnClose={true}
            width="60vw"
            style={{ maxWidth: "900px" }}
            closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
          >
            <FrequencyShiftsModal
              frequency={frequency}
              task={selectedTask}
              onClose={() => setShiftsModal(false)}
            />
          </Modal>
          <Modal
            className="cs-modal-v2"
            centered
            footer={null}
            visible={showDetails}
            onCancel={() => setShowDetails(false)}
            maskClosable={false}
            bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
            destroyOnClose={true}
            width="60vw"
            style={{ maxWidth: "900px" }}
            closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
          >
            <DetailsModal frequency={frequency} taskDetails={selectedTask} />
          </Modal>
        </div>
      </div>
    </div>
  )
}

const DetailsModal = ({ frequency, taskDetails }) => {

  const [estimate, setEstimate] = useState({});
  const [total, setTotal] = useState("$0.00");

  useEffect(() => {
    if (frequency) {
      let type = taskDetails.estimate && taskDetails.estimate.estimate_type ? taskDetails.estimate.estimate_type : null;
      let total = "$0.00";
      if (type !== null) {
        let details = {}
        switch (type) {
          case "area": details = {
            "SQM": taskDetails.estimate.sqm,
            "Rate": currencyFormat(taskDetails.estimate.rate),
          }
            total = currencyFormat(taskDetails.estimate.sqm * taskDetails.estimate.rate)
            break;
          case "hours":
            details = {
              "Days": taskDetails.estimate.days,
              "Staff": taskDetails.estimate.staff,
              "Hours": taskDetails.estimate.hours,
              "Rate": currencyFormat(taskDetails.estimate.rate),
            }
            total = currencyFormat(taskDetails.estimate.days * taskDetails.estimate.staff * taskDetails.estimate.hours * taskDetails.estimate.rate)
            break;
          case "quant":
            details = {
              "Quantity": taskDetails.estimate.quant,
              "Rate": currencyFormat(taskDetails.estimate.rate),
            }
            total = currencyFormat(taskDetails.estimate.quant * taskDetails.estimate.rate)
            break;
          default: details = {};
        }
        setTotal(total);
        setEstimate(details);
      }
    }
  }, [frequency, taskDetails]);

  return (
    <div className="v2-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={`container-fluid px-0`}>
              <div className="row">
                {Object.keys(estimate).length > 0 && <div className="col-4 pr-0">
                  <div className={styles.Label}>Task Details</div>
                  <div className={styles.card}>
                    <div className="">
                      {Object.entries(estimate).map(([key, value]) => (
                        <div className="d-flex justify-content-between" key={key}>
                          <div className={`${styles.PrimaryText} font-weight-bold`}>
                            {key}
                          </div>
                          <div className="font-weight-bold">{value}</div>
                        </div>
                      ))}
                    </div>
                    <hr className={styles.greyHR} />
                    <div className="d-flex justify-content-between">
                      <div className={`${styles.PrimaryText} font-weight-bold`}>Total</div>
                      <div className="font-weight-bold">{total}</div>
                    </div>
                  </div>
                </div>}
                <div className="col-8 d-flex " style={{ alignSelf: "stretch", flexDirection: "column" }}>
                  <div className={styles.Label}>Task Notes</div>
                  <textarea disabled className={`${v2Styles.TextArea} freq-note-textarea`}>
                    {taskDetails.task_note ? taskDetails.task_note : ""}
                  </textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <hr />
                  <div className={styles.Label}>Task Conditions</div>
                  <div className="freq-task-conditions">
                    <div dangerouslySetInnerHTML={{
                      __html: taskDetails.task_conditions ? taskDetails.task_conditions : ""
                    }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}